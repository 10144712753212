import { useState } from 'react'; 
import Axios from 'axios'
import '../App.css'
import mySql from 'mysql';

const Prihlaska = () => {
    const [ kidName, setKidName] = useState();
    const [ kidSurname, setKidSurname] = useState();
    const [ kidBirth, setKidBirth] = useState();
    const [ kidInsurance, setKidInsurance] = useState();
    const [ wantWithFriend, setWantWithFriend] = useState();
    const [ friendName, setFriendName] = useState();
    const [ kidStreet, setKidStreet] = useState();
    const [ kidCity, setKidCity] = useState();
    const [ kidPSC, setKidPSC] = useState();
    const [ motherName, setMotherName] = useState();
    const [ motherSurname, setMotherSurname] = useState();
    const [ motherPhone, setMotherPhone] = useState();
    const [ motherMail, setMotherMail] = useState();
    const [ fatherName, setFatherName] = useState();
    const [ fatherSurname, setFatherSurname] = useState();
    const [ fatherPhone, setFatherPhone] = useState();
    const [ fatherMail, setFatherMail] = useState();
    const [ consentGDPR, setConsentGDPR] = useState();
    const [ wantDVD, setWantDVD] = useState();
    const [ consentRad, setConsentRad] = useState();
    const [ consentStorno, setConsentStorno] = useState();
    const [ consentValid, setConsentValid] = useState();
    const [ poznamka, setPoznamka] = useState();
    const [ platba, setPlatba] = useState();
    const [ doprava, setDoprava] = useState();

    


    const handleSubmit = (e) => {
        e.preventDefault();

        const newPrihlaska = {
            kidName, 
            kidSurname, 
            kidBirth, 
            kidInsurance, 
            wantWithFriend, 
            friendName, 
            kidStreet, 
            kidCity, 
            kidPSC, 
            motherName, 
            motherSurname, 
            motherPhone, 
            motherMail, 
            fatherName, 
            fatherSurname, 
            fatherPhone, 
            fatherMail, 
            wantDVD,
            poznamka,
            platba,
            doprava
        };

        submitPost();
    }

    const submitPost = () => {
        Axios.post('http://md381.wedos.net:3002/api/create', {kidName: kidName, kidSurname: kidSurname, kidBirth: kidBirth, kidInsurance: kidInsurance, wantWithFriend: wantWithFriend, friendName: friendName, kidStreet: kidStreet, kidCity: kidCity, kidPSC: kidPSC, motherName: motherName, motherSurname: motherSurname, motherPhone: motherPhone, motherMail: motherMail, fatherName: fatherName, fatherSurname: fatherSurname, fatherPhone: fatherSurname, fatherMail: fatherMail, wantDVD: wantDVD, poznamka: poznamka, platba: platba, doprava: doprava})
    }

    


    return ( 
        <div className='Prihlaska'>
            {/* <main>
                <h1>On-line přihláška dítěte</h1>

                <form onSubmit={handleSubmit}>
                    <h2>Informace o dítěti</h2>
                    <label>Jméno(a) dítěte: </label>
                    <input type='text' required value={kidName} onChange={(e) => setKidName(e.target.value)}/>
                    
                    <label>     Příjmení dítěte: </label>
                    <input type='text' required value={kidSurname} onChange={(e) => setKidSurname(e.target.value)}/><br/>

                    <label>Datum narození dítěte</label>
                    <input type='date' required value={kidBirth} onChange={(e) => setKidBirth(e.target.value)}/>
                    
                    <label>     Zdravotní pojišťovna: </label>
                    <input type='text' required value={kidInsurance} onChange={(e) => setKidInsurance(e.target.value)}/><br/><br/>

                    <input type='checkbox' value={wantWithFriend} onChange={(e) => setWantWithFriend(e.target.checked)}/>
                    <label>Dítě má zájem bydlet s kamarádem na chatce</label>

                    <label>     Jméno(a) kamaráda(ů)</label>
                    <input type='text' value={friendName} onChange={(e) => setFriendName(e.target.value)}/><br/>

                    <h2>Adresa</h2>
                    <label>Ulice a číslo popisné: </label>
                    <input type='text' required value={kidStreet} onChange={(e) => setKidStreet(e.target.value)}/><br/>

                    <label>Město: </label>
                    <input type='text' required value={kidCity} onChange={(e) => setKidCity(e.target.value)}/>

                    <label>     PSČ: </label>
                    <input type='text' required value={kidPSC} onChange={(e) => setKidPSC(e.target.value)}/><br/>



                    <h2>Kontakty na rodiče</h2>
                    <label>Jméno(a) matky: </label>
                    <input type='text' required value={motherName} onChange={(e) => setMotherName(e.target.value)}/>

                    <label>     Příjmení matky: </label>
                    <input type='text' required value={motherSurname} onChange={(e) => setMotherSurname(e.target.value)}/><br/>

                    <label>Tel. číslo matky: </label>
                    <input type='text' required value={motherPhone} onChange={(e) => setMotherPhone(e.target.value)}/>
                    
                    <label>     E-mailová adresa matky: </label>
                    <input type='text' required value={motherMail} onChange={(e) => setMotherMail(e.target.value)}/><br/><br/>


                    <label>Jméno(a) otce: </label>
                    <input type='text' required value={fatherName} onChange={(e) => setFatherName(e.target.value)}/>

                    <label>     Příjmení otce: </label>
                    <input type='text' required value={fatherSurname} onChange={(e) => setFatherSurname(e.target.value)}/><br/>

                    <label>Tel. číslo otce: </label>
                    <input type='text' required value={fatherPhone} onChange={(e) => setFatherPhone(e.target.value)}/>

                    <label>     E-mailová adresa otce: </label>
                    <input type='text' required value={fatherMail} onChange={(e) => setFatherMail(e.target.value)}/><br/>

                    <h2>Platba a doprava</h2>
                    <label>Způsob platby: </label>
                    <select required value={platba} onChange={(e) => setPlatba(e.target.value)}>
                        <option value="hotovost">Hotovost</option>
                        <option value="faktura">Faktura (Zaměstnavatel)</option>
                        <option value="prevod">Bankovním převodem</option>
                    </select>

                    <label>Způsob dopravení se: </label>
                    <select required value={doprava} onChange={(e) => setDoprava(e.target.value)}>
                        <option value="vlastni">Po vlastní ose</option>
                        <option value="krumlov">Autobusem (Moravský Krumlov)</option>
                        <option value="tesetice">Autobusem (Těšetice)</option>
                        <option value="lesna">Autobusem (Lesná)</option>
                    </select>

                    <h2>Prostor na poznámky</h2>
                    <textarea value={poznamka} onChange={(e) => setPoznamka(e.target.value)} />

                    <h2>Povolení</h2>
                    <input type='checkbox' required value={consentGDPR} onChange={(e) => setConsentGDPR(e.target.checked)}/>
                    <label>Souhlasíme se zpracováním osobních údajů podle evropského nařízení GDPR.</label><br/>
                    
                    <input type='checkbox' required value={wantDVD} onChange={(e) => setWantDVD(e.target.checked)}/>
                    <label>Máme zájem o FlashDisk s digitálními fotkami popř. videem z celého průběhu letního tábora. (Cena: 200,-Kč, platí se zvlášť na zmíněný účet, vše bude upřesněno v pozdějších pokynech LT.)</label><br/>
                
                    <input type='checkbox' required value={consentRad} onChange={(e) => setConsentRad(e.target.checked)}/>
                    <label>Bereme na vědomí, že hrubé porušení táborového řádu může vést k vyloučení dítěte z tábora, bez nároku na vrácení táborového poplatku.</label><br/>

                    <input type='checkbox' required value={consentStorno} onChange={(e) => setConsentStorno(e.target.checked)}/>
                    <label>Souhlasíme se storno poplatky uvedenými v sekce storno poplatky.</label><br/>

                    <input type='checkbox' required value={consentValid} onChange={(e) => setConsentValid(e.target.checked)}/>
                    <label>Potvrzujeme, že tato přihláška je závazná.</label><br/><br/>

                    <input type='submit' />
                </form>
            </main>  */}

            <main>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfZlojDBnDCnOt5zAdh-wcU8GqoQGtkw71gXp3kqUS-YooPcw/viewform?embedded=true"  width="100%" height="773" frameborder="0" marginheight="0" marginwidth="0">Načítání…</iframe>
            </main>
        </div>
     );
}
 
export default Prihlaska;