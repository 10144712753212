import './App.css';
import Navbar from './Bars/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Downbar from './Bars/Downbar';
import Contact from './pages/Contact';
import Documents from './pages/Documents';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PhotoGalery from './pages/Photogalery';
import NotFound from './pages/ErrorPages/NotFound';
import Prihlaska from './pages/Prihlaska';
import React, { useState } from 'react';
import TaborRad from './pages/Dokumenty/TaborRad';


function App() {

  return (
      <Router>
      <div className="App">
        <Navbar />
        <div className='content'>
          <Switch>
            <Route exact path="/">
              <Home/>
            </Route>

            <Route path="/kontakty">
              <Contact/>
            </Route>

            <Route path='/onas'>
              <About/>
            </Route>

            <Route exact path='/dokumenty'>
              <Documents />
            </Route>

            <Route path='/dokumenty/taborovy-rad'>
                <TaborRad />
              </Route>

            <Route path='/fotogalerie'>
              <PhotoGalery />
            </Route>

            <Route path='/prihlasky'>
              <Prihlaska />
            </Route>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
        <Downbar />
      </div>
    </Router>
    );
}

export default App;
