import '../App.css';
import plakat from '../images/plakats/2024.jpg'

function Home() {
  return (
    <div className="Home">
      <main>
            <h1>Letní dětský tábor Rozmahelová</h1>

            <p className='taborName'>BOROVINA 2024</p>

            <img src={plakat} width={100 + "%"}/>

        </main>
    </div>
  );
}

export default Home;