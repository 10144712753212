import '../App.css';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';



function Navbar() {
  const [activeHome, setActiveHome] = useState('non');
  const [activeOnas, setActiveOnas] = useState('non');
  const [activeKont, setActiveKont] = useState('non');
  const [activeFoto, setActiveFoto] = useState('non');
  const [activeDoku, setActiveDoku] = useState('non');


  
  const [isResponse, setIsResponse] = useState('statnav');


  const myFunction = () => {
    if (isResponse === "statnav") {
      setIsResponse("responsive");
    } else {
      setIsResponse("statnav");
    }
  }



  let location = useLocation();




  useEffect(() => {
    closeAll();
    switch (location.pathname) {
      case '/onas':
        setActiveOnas("active");
        break;  
      case '/kontakty':
        setActiveKont("active");
        break;
      case '/fotogalerie':
        setActiveFoto("active");
        break;
      case '/dokumenty':
        setActiveDoku('active');
        break;
      case '/dokumenty/taborovy-rad':
      setActiveDoku('active');
      break;
      case '/':
        setActiveHome("active");
        break;
    }
  });

  function closeAll() {
    setActiveHome("non");
    setActiveOnas("non");
    setActiveKont("non");
    setActiveFoto("non");
    setActiveDoku('non');
  } 

  return (
    <div className='Navbar'>
      <header id='stickyNavbar'>
            <div id="statnav" className={isResponse}>
                <Link className={ activeHome } to='/'>Domů</Link>
                <Link className={ activeOnas } to='/onas'>O nás</Link>
                <Link className={ activeKont } to='/kontakty'>Kontakty</Link>
                <Link className={ activeFoto } to='/fotogalerie'>Fotografie</Link>
                <div class="dropdown">
                  <div></div>
                  <Link className={ activeDoku } id='dropbtn' to='/dokumenty'>Dokumenty</Link>
                  <div class="dropdown-content">
                    <div></div>
                    <Link to="/dokumenty/taborovy-rad">Táborový řád</Link>
                    <Link to="#">Storno podmínky</Link>
                    <Link to="/dokumenty">Ke stažení</Link>
                  </div>
                </div>
                <div className='statnav-right'>
                  <div />
                  <Link to='/prihlasky'>Příhláška na tábor</Link>
                  <a href="javascript:void(0);" class="icon" onClick={myFunction}>
                    <div class="horizontalLine"></div>
                    <div class="horizontalLine"></div>
                    <div class="horizontalLine"></div>
                    <div class="horizontalLine"></div>
                  </a>
                </div>
            </div>
        </header>
    </div>
  );
}




export default Navbar;