import {Link} from 'react-router-dom';


const NotFound = () => {
    return ( 
        <div className="NotFound">
            <main>
                <h2>Omlouváme se!</h2>
                <p>Bohužel vámi hledanou stránku nebylo možné nalézt</p>
                <Link to="/">Zpět na domovskou stránku...</Link>
            </main>
        </div>
     );
}
 
export default NotFound;