import '../App.css';
import Cipis from "../images/people/vedouci/Cipis.png";
import Standa from "../images/people/vedouci/Standa.png";
import Rene from '../images/people/vedouci/Rene.png';
import Monca from '../images/people/vedouci/Monca.png';
import Vladka from '../images/people/vedouci/Vladka.png';
import Anicka from '../images/people/vedouci/Anicka.png';

import OSP from '../images/sponsors/OSP.png';
import Optika from '../images/sponsors/optika.svg';

function About() {
  return (
    <div className="About">
      <main className='lotText'>
        <h1>O nás</h1>
        <h2>Historie</h2>
        <p>Náš spolek vznikl roku 2002 na žádost spokojených dětí i rodičů. Byly to děti, které s námi jezdily pravidelně na letní tábory i soustředění konané dříve pod Domovem dětí a mládeže ( DDM). Od této instituce jsme se odtrhli a vyklubal se tak nový projekt "tábor s Vlaďkou", který trvá až dodnes. V roce 2024 oslavujeme již 23. rok provozu a jsme moc pyšní, že v tomto duchu pokračujeme dál. Vlak, který rozjel 1. ročník byl svým způsobem obyčejný, nastoupilo do něj 35 dětí. V 19. ročníku už nás bylo 110 dětí . Vůbec jsme nepotřebovali žádnou reklamu, protože tu nám dělali sami účastnící tábora. Každým rokem bylo a je naší prioritou vymýšlet spoustu krásných témat. Pro zpestření programu za námi jezdili vzácné návštěvy ( viz. foto galerie). Jezdili jsme na výlety, užívali si sluníčka, vody i přírody. Jsme hrdí na to, že z našich dětí, které jezdí s námi mnoho let se stávají praktikanta a později kvalitní oddíloví vedoucí. Ostatní co vyrostli do dospělosti jezdí stále za námi zavzpomínat na staré časy. Našim cílem je dělat dětem radost. Chceme, aby jejich vzpomínky na letní tábory byly nezapomenutelné.</p>

        <h2>Hlavní vedoucí</h2>
        <h3>Kdo jsem?</h3>
        <p>Jmenuji se Vladimíra Rozmahelová. Práce s dětmi je mým posláním. Dětem se věnuji mnoho let jako pedagog, ale také ve volnočasových aktivitách nezahálím. Ve svých 15.letech jsem vedla, společně s tatínkem, cvičení v Sokole. V období dospívání mi bylo nabídnuto vedení kroužků pod Domem dětí a mládeže v Moravském Krumlově. Vystudovala jsem obor vychovatelství a začala jsem pracovat mnoho let ve zmíněné instituci. Zde jsem získala mnoho zkušeností s letními tábory a dalšími kurzy pro děti. Čas však běžel a bylo na místě odejít dál. Cesta mě zavedla na SŠDOS v Moravském Krumlově. Zde jsem pracovala jako paní učitelka, ale také i jako paní vychovatelka. Tábory na žádost dětí a rodičů pokračovaly dál. V průběhu zaměstnání jsem vystudovala vysokou školu Masarykovy univerzity obor Speciální pedagog pro učitelství. Díky tomu se mi otevřely další dveře pro seberozvoj a získávání dalších nových zkušeností. Odešla jsem z Moravského Krumlova a začala učit na ZŠ Olbramovice a hned potom na ZŠ Miroslav, kde působím dodnes. Dětský tábor v období letních prázdnin pořádám již 25 let. Jsem na to velice pyšná. Vrací se k nám děti ze základních škol, ale také už děti přerostlé, které nám pomáhají na LT. Zprvu jako praktikanti a později jako vedoucí. Díky těmto vazbám znám velice dobře svůj tým. Jsme skvělá parta a vždy se každým rokem na sebe moc těšíme.
        </p>

        <h3>Co si o mně myslí moji vedoucí?</h3>
        <p>Vlaďku znám již od svých patnácti let, kdy byla vychovatelkou na internátě při škole v Moravském Krumlově. Takže když mě oslovila na konci mého studia, na této škole, zda s ní a dalšími nechci jet na tábor jako vedoucí oddílu, zprvu jsem váhal. Zda jsem ten pravý k tomuto účelu. Vlaďka mě oznámila, že tak jak mě zná za ty roky, tak že to jistě zvládnu a nebudu na to sám. S ujištěním pomoci a podpory jsem se vydal na svůj první tábor ve svých 20ti letech. Od té doby jsem součástí jejího kolektivu již po dobu čtrnácti let. Vlaďka je člověk co musí mít vše pod kontrolou a řádně zajištěné. Avšak to k chodu a všemu kolem pořádání letního tábora samozřejmě patří. Tábor vede pevnou rukou, avšak s přátelským postojem. Aby věci fungovaly jak mají, je zapotřebí řád a pravidla. Bez toho by to ani nešlo. Vlaďka dělá tábory již několik let a má tak mnoho zkušeností, které ji napomáhají ke správnému chodu tábora a myslím si, že ji již málo věcí zaskočí. Ocitla se v mnoha situacích, kdy musela řešit mnohé problémy ze strany ubytovatelů, ale vždy si s nimi poradila a tábor mohl pokračovat v plném proudu, tak jak se od nás očekávalo. Sice to mnohdy nebylo příjemné, ale vždy jsme věděli, že se na ni můžeme obrátit a funguje to i oboustranně. Nejsou problémy co by neměly řešení. Spolupráce s Vlaďkou mě vyhovuje a těším se každým rokem na další rok. Až se znovu sejdeme a pomyslně odškrtneme další úspěšný tábor na jeho konci. 
          <br />
          <em>Standa - vedoucí oddílu (34 let)</em>
          </p>



        <h2>Medailonky vedoucích</h2> 

        <div className='medailonky'>
          
          <div className='medailonek'>
            <img src={Vladka}/>
            <div>
              <h3>Vladimíra „Vlaďka“ Rozmahelová</h3>
              <em>Hlavní vedoucí</em>
              <p>Mým velkým posláním je dělat dětem radost. Jako hlavní vedoucí intenzivně pracuji na organizaci celého tábora. Nejvíce mi záleží na tom, aby naše děti byly velice spokojené a rády se vracely za námi.</p>
            </div>
          </div>

          <div className='medailonek'>
            <img src={Monca}/>
            <div>
              <h3>Monika „Monča“ Vymětalová</h3>
              <em>Zdravuška</em>
              <p>S radostí se postarám o jakéhokoliv raubíře ať už jde o akutní nebo chronický zdravotní problém.</p>
            </div>
          </div>

          <div className='medailonek'>
            <img src={Cipis}/>
            <div className='medailonekTextArea'>
              <h3>David „Cipis“ Podlaha</h3>
              <em>Zástupce hlavní vedoucí</em>
              <p>Mým největším úkolem na  LT, je vytvořit dobrý a kvalitní program pro děti jako programátor a oddílový vedoucí. Zastupuji hlavního vedoucího v době nepřítomnosti. Tábor je pro mě neodmyslitelnou součástí léta a díky skvělému kolektivu a prostředí si práci na táboře s dětmi skutečně užívám.</p>
              </div>
          </div>

          <div className='medailonek'>
            <img src={Standa}/>
            <div>
              <h3>Standa „Stanik“ Vymětal</h3>
              <em>Programový vedoucí</em>
              <p>Na táboře se podílím na organizaci programu a zájmových činností pro děti již 15 let. Vedu svůj oddíl průběhem tábora férově a vždy doufám, že si z něj odnesou jen pozitiva a nabyté zkušenosti, které se jim jednou budou hodit. </p>
            </div>
          </div>

          <div className='medailonek'>
            <img src={Rene}/>
            <div>
              <h3>René „Renda“ Vymětal</h3>
              <em>Oddílový vedoucí</em>
              <p>Sběratel co sbírá především zkušenosti. Jsem pro každou srandu a rád pracuji s dětmi. Mnohdy jsou rozumnější než dospělí.</p>
            </div>
          </div>

          <div className='medailonek'>
            <img src={Anicka}/>
            <div>
              <h3>Anna Marie „Anička“ Kocandová</h3>
              <em>Oddílová vedoucí</em>
              <p>Už jako dítě jsem milovala letní tábory s Vlaďkou a ani v dospělosti se to nezměnilo. Každý rok se těším na tyto dny v přírodě, kde tvoříme skvělý tým s dětmi, vytváříme si nové zážitky a jsme v báječném kolektivu.</p>
            </div>
          </div>
        </div>

        <h2>Co o nás říkají</h2>
        <p>Když dojely mé dcery z tábora s Vlaďkou R., byly úplně v úžasu co velkého na něm zažily. Nejvíce byly nadšené z her. Díky táboru zažily hezké prázdniny a ještě do dnes vzpomínají jak tam bylo hezky. Děkuji všem, kteří se o mé děti tak hezky starali.

<br /><em>Monika Hlaváčková</em></p>

<p>
Letní tábor pod vedením Vladimíry Rozmahelové a jejího týmu všem vřele doporučuji.

Bylo to nadupaných 10 dni, obzvlášť oceňuji přístup vedoucích, jejich komunikaci s dětmi a velmi bohatý program co pro děti mají vždy připravený.

Na tábor jezdí syn  již 6 let a vždy se vrátí smutný, ze už skončil…

  <br />
  <em>Zuzana Chylíková</em>
</p>

<p>Jako máma jsem poprvé v roce 2023 svěřila svou dceru do rukou vedoucích. O to komplikovanější to je, protože malá má diagnostikovanou epilepsii. Vedoucí vyslechli mé pokyny a edukaci pro případný záchvat, ale i co mu předchází. A věnovaly se mé dceři naprosto jako k jiným dětem, takže si mohla vyzkoušet i různé aktivity, a k tomu naprosto profesionálně ze strany prevence záchvatu.
<br /> <em>Monika Vymětalová</em>
</p>
        <h2>Naši Sponzoři</h2>
        <p>Děkujeme našim sponzorům za finanční podporu a dary</p>
        <a href="https://osp-mk.cz/"><img src={OSP} alt="Stavebniny OSP Moravský Krumlov" height="50px" /></a>
        <a href="https://www.optikakocandova.cz/"><img src={Optika} alt="Optika Kocandová" height="50px" style={{backgroundColor: "rgb(236, 72, 50)"}} /></a>


        <p>Chcete se stát sponzorem? <a href="mailto:walla.petr@mkgym.cz">Konktaktujte nás!</a></p>
      </main>
    </div>
  );
}

export default About;