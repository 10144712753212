import '../App.css';
import ImageGallery from "react-image-gallery";
import React from 'react';
import "react-image-gallery/styles/css/image-gallery.css";

//Fotky 2023
import celyTabor from '../images/show/full/celyTabor.jpeg';
import metkaynove from '../images/show/full/metkaynove.jpg';
import reneRoh from '../images/show/full/reneRoh.jpg';
import foto2301 from '../images/show/2023/AVATARLT2023.png';
import foto2302 from '../images/show/2023/PXL_20230811_131451269.MP.jpg';
import foto2303 from '../images/show/2023/Screenshot_20230811_221517_Gallery.jpg';
import foto2304 from '../images/show/2023/Screenshot_20230811_223230_Gallery.jpg';
import foto2305 from '../images/show/2023/Screenshot_20230811_223749_Gallery.jpg';
import foto2306 from '../images/show/2023/Screenshot_20230811_225423_Gallery.jpg';
import foto2307 from '../images/show/2023/Screenshot_20230811_225521_Gallery.jpg';
import foto2308 from '../images/show/2023/IMG_7615.JPG';
import foto2309 from '../images/show/2023/IMG_7724.JPG';
import foto2310 from '../images/show/2023/IMG_8006.JPG';
import foto2311 from '../images/show/2023/IMG_8175.JPG';
import foto2312 from '../images/show/2023/IMG_E7773.JPG';



import drak from '../images/show/full/drak.jpg';
import jogurt from '../images/show/full/jogurt.jpg';
import oddil from '../images/show/full/oddil.jpg';


// //Fotky 2022
import taborak from '../images/show/2022/go_photo_1660312153316.jpg';
import foto2201 from '../images/show/2022/IMG20220811150437.jpg';
import foto2202 from '../images/show/2022/IMG20220807203914.jpg';
import foto2203 from '../images/show/2022/IMG20220810104531.jpg';
import foto2204 from '../images/show/2022/IMG20220811150259.jpg';
import foto2205 from '../images/show/2022/IMG20220811165649.jpg';
import foto2206 from '../images/show/2022/IMG20220811210405.jpg';
import foto2207 from '../images/show/2022/IMG_20220808_102028.jpg';
import foto2208 from '../images/show/2022/IMG_20220811_154722.jpg';

// //Fotky 2021
import foto2101 from '../images/show/2021/20210802_172036.jpg';
import foto2102 from '../images/show/2021/20210809_102915.jpg';
import foto2103 from '../images/show/2021/20210809_165802.jpg';
import foto2104 from '../images/show/2021/20210809_165932.jpg';
import foto2105 from '../images/show/2021/IMG_20210802_163918.jpg';
import foto2106 from '../images/show/2021/IMG_20210806_101446.jpg';
import foto2107 from '../images/show/2021/IMG_20210803_213529_BURST001_COVER.jpg';
import foto2108 from '../images/show/2021/IMG_3526.JPG';
import foto2109 from '../images/show/2021/IMG_3554.JPG';
import foto2110 from '../images/show/2021/IMG_3680.JPG';

//Fotky 2019
import foto1901 from '../images/show/2019/IMG_20190802_100844.jpg';
import foto1902 from '../images/show/2019/IMG_20190804_191612.jpg';
import foto1903 from '../images/show/2019/IMG_20190805_205017.jpg';
import foto1904 from '../images/show/2019/IMG_20190806_100454.jpg';
import foto1905 from '../images/show/2019/IMG_20190806_151402.jpg';
import foto1906 from '../images/show/2019/IMG_20190806_153154.jpg';
import foto1907 from '../images/show/2019/IMG_20190806_155607.jpg';
import foto1908 from '../images/show/2019/IMG_20190806_203713.jpg';
import foto1909 from '../images/show/2019/IMG_20190806_211157.jpg';
import foto1910 from '../images/show/2019/IMG_20190806_212135.jpg';
import foto1911 from '../images/show/2019/IMG_20190806_220409.jpg';
import foto1912 from '../images/show/2019/IMG_20190808_104940.jpg';
import foto1913 from '../images/show/2019/IMG_20190808_151157.jpg';
import foto1914 from '../images/show/2019/IMG_20190808_155314.jpg';
import foto1915 from '../images/show/2019/IMG_20190808_155845.jpg';
import foto1916 from '../images/show/2019/IMG_20190808_160921.jpg';
import foto1917 from '../images/show/2019/IMG_20190808_161917.jpg';
import foto1918 from '../images/show/2019/IMG_E6027.JPG';
import foto1919 from '../images/show/2019/IMG_E6083.JPG';
import foto1920 from '../images/show/2019/IMG_E6095.JPG';
import foto1921 from '../images/show/2019/DSC_0096.JPG';
import foto1922 from '../images/show/2019/DSC_0108.JPG';
import foto1923 from '../images/show/2019/DSC_0138.JPG';
import foto1924 from '../images/show/2019/DSC_0154.JPG';
import foto1925 from '../images/show/2019/DSC_0158.JPG';
import foto1926 from '../images/show/2019/DSC_0180.JPG';
import foto1927 from '../images/show/2019/DSC_0255.JPG';
import foto1928 from '../images/show/2019/IMG_5483.JPG';
import foto1929 from '../images/show/2019/IMG_5551.JPG';
import foto1930 from '../images/show/2019/IMG_5620.JPG';
import foto1931 from '../images/show/2019/IMG_5636.JPG';
import foto1932 from '../images/show/2019/IMG_5814.JPG';
import foto1933 from '../images/show/2019/IMG_5815.JPG';
import foto1934 from '../images/show/2019/IMG_6007.JPG';
import foto1935 from '../images/show/2019/IMG_6026.JPG';
import foto1936 from '../images/show/2019/IMG_6332.JPG';
import foto1937 from '../images/show/2019/IMG_6336.JPG';
import foto1938 from '../images/show/2019/DSC09355.JPG';
import foto1939 from '../images/show/2019/DSC09364.JPG';
import foto1940 from '../images/show/2019/DSC09397.JPG';
import foto1941 from '../images/show/2019/DSC09455.JPG';
import foto1942 from '../images/show/2019/DSC09468.JPG';
import foto1943 from '../images/show/2019/DSC09476.JPG';
import foto1944 from '../images/show/2019/DSC09497.JPG';
import foto1945 from '../images/show/2019/DSC09505.JPG';
import foto1946 from '../images/show/2019/DSC09525.JPG';
import foto1947 from '../images/show/2019/DSC09581.JPG';

//Fotky 2009
import foto0901 from '../images/show/2009/P8100002.JPG';
import foto0902 from '../images/show/2009/P8100048.JPG';
import foto0903 from '../images/show/2009/P8110168.JPG';
import foto0904 from '../images/show/2009/P8120242.JPG';
import foto0905 from '../images/show/2009/P8120252.JPG';
import foto0906 from '../images/show/2009/P8120259.JPG';
import foto0907 from '../images/show/2009/P8120317.JPG';
import foto0908 from '../images/show/2009/P8130338.JPG';
import foto0909 from '../images/show/2009/P8130441.JPG';

//Fotky 2005
import foto0501 from '../images/show/2005/P8080032.JPG';
import foto0502 from '../images/show/2005/P8080039.JPG';
import foto0503 from '../images/show/2005/P8080043.JPG';
import foto0504 from '../images/show/2005/P8080044.JPG';
import foto0505 from '../images/show/2005/P8080050.JPG';
import foto0506 from '../images/show/2005/P8091326.JPG';
import foto0507 from '../images/show/2005/P8091327.JPG';
import foto0508 from '../images/show/2005/P8091328.JPG';
import foto0509 from '../images/show/2005/P8091329.JPG';
import foto0510 from '../images/show/2005/P8130082.JPG';
import foto0511 from '../images/show/2005/P8130084.JPG';
import foto0512 from '../images/show/2005/P8140153.JPG';
import foto0513 from '../images/show/2005/P8170024.JPG';
import foto0514 from '../images/show/2005/P8170051.JPG';
import foto0515 from '../images/show/2005/P8170080.JPG';
import foto0516 from '../images/show/2005/P8170081.JPG';

//Fotky 2004
import auto from '../images/show/2004/P8110003.JPG';
import bazen from '../images/show/2004/P8110050.JPG';
import policejniAutoBok from '../images/show/2004/P8120059.JPG';
import policejniAutoPred from '../images/show/2004/P8120060.JPG';
import hasicak from '../images/show/2004/P8140022.JPG';
import hasicakStrika from '../images/show/2004/P8140023.JPG';
import detiHasici from '../images/show/2004/P8140032.JPG';
import prazdneHriste from '../images/show/2004/P8160136.JPG';
import obleky from '../images/show/2004/P8170151.JPG';
import prehrada from '../images/show/2004/P8170152.JPG';
import reka from '../images/show/2004/P8170153.JPG';
import muzeum from '../images/show/2004/P8170158.JPG';
import zasah from '../images/show/2004/P8170171.JPG';
import poliDodavka from '../images/show/2004/P8180183.JPG';


const images2023 = [
  {
    original: foto2301,
    thumbnail: foto2301
  },
  {
    original: foto2302,
    thumbnail: foto2302
  },
  {
    original: foto2303,
    thumbnail: foto2303
  },
  {
    original: foto2304,
    thumbnail: foto2304
  },
  {
    original: foto2305,
    thumbnail: foto2305
  },
  {
    original: foto2306,
    thumbnail: foto2306
  },
  {
    original: foto2307,
    thumbnail: foto2307
  },
  {
    original: foto2308,
    thumbnail: foto2308
  },
  {
    original: foto2309,
    thumbnail: foto2309
  },
  {
    original: foto2310,
    thumbnail: foto2311
  },
  {
    original: foto2312,
    thumbnail: foto2312
  },
  {
    original: celyTabor,
    thumbnail: celyTabor,
    originalAlt: "Fotka celého tábora"
  },
  {
    original: reneRoh,
    thumbnail: reneRoh,
    originalAlt: "Fotka René rohu"
  },
  {
    original: metkaynove,
    thumbnail: metkaynove,
    originalAlt: "Fotka Standova oddílu"
  },
];

  const images2022 = [
    {
      original: taborak,
      thumbnail: taborak,
    },
    {
      original: foto2201,
      thumbnail: foto2201,
    },
    {
      original: foto2202,
      thumbnail: foto2202,
    },
    {
      original: foto2203,
      thumbnail: foto2203,
    },
    {
      original: foto2204,
      thumbnail: foto2204,
    },
    {
      original: foto2205,
      thumbnail: foto2205,
    },
    {
      original: foto2206,
      thumbnail: foto2206,
    },
    {
      original: foto2207,
      thumbnail: foto2207,
    },
    {
      original: foto2208,
      thumbnail: foto2208,
    },
  ];

   const images2021 = [
     {
       original: foto2101,
       thumbnail: foto2101
     },
     {
       original: foto2102,
       thumbnail: foto2102
     },
     {
      original: foto2103,
       thumbnail: foto2103
     },
     {
       original: foto2104,
       thumbnail: foto2104
    },
     {
      original: foto2105,
       thumbnail: foto2105
     },
     {
       original: foto2106,
       thumbnail: foto2106
     },
     {
       original: foto2107,
       thumbnail: foto2107
     },
     {
       original: foto2108,
       thumbnail: foto2108
     },
     {
       original: foto2109,
       thumbnail: foto2109
     },
     {
       original: foto2110,
       thumbnail: foto2110
     },
   ];

  const images2020 = [
    {
      original: drak,
      thumbnail: drak,
      originalAlt: "Fotka draka z táborové hry"
    },
    {
      original: jogurt,
      thumbnail: jogurt,
      originalAlt: "Fotka táborníků při jogurtové hře"
    },
    {
      original: oddil,
      thumbnail: oddil,
      originalAlt: "Oddílová fotka „Na Skále“"
    },
  ];

  const images2019 = [
    {
      original: foto1901,
      thumbnail: foto1901
    },{
      original: foto1902,
      thumbnail: foto1902
    },{
      original: foto1903,
      thumbnail: foto1903
    },{
      original: foto1904,
      thumbnail: foto1904
    },{
      original: foto1905,
      thumbnail: foto1905
    },{
      original: foto1906,
      thumbnail: foto1906
    },{
      original: foto1907,
      thumbnail: foto1907
    },{
      original: foto1908,
      thumbnail: foto1908
    },{
      original: foto1909,
      thumbnail: foto1909
    },{
      original: foto1910,
      thumbnail: foto1910
    },{
      original: foto1911,
      thumbnail: foto1911
    },{
      original: foto1912,
      thumbnail: foto1912
    },{
      original: foto1913,
      thumbnail: foto1913
    },{
      original: foto1914,
      thumbnail: foto1914
    },{
      original: foto1915,
      thumbnail: foto1915
    },{
      original: foto1916,
      thumbnail: foto1916
    },{
      original: foto1917,
      thumbnail: foto1917
    },{
      original: foto1918,
      thumbnail: foto1918
    },{
      original: foto1919,
      thumbnail: foto1919
    },{
      original: foto1920,
      thumbnail: foto1920
    },{
      original: foto1921,
      thumbnail: foto1921
    },{
      original: foto1922,
      thumbnail: foto1922
    },{
      original: foto1923,
      thumbnail: foto1923
    },{
      original: foto1924,
      thumbnail: foto1924
    },{
      original: foto1925,
      thumbnail: foto1925
    },{
      original: foto1926,
      thumbnail: foto1926
    },{
      original: foto1927,
      thumbnail: foto1927
    },{
      original: foto1928,
      thumbnail: foto1928
    },{
      original: foto1929,
      thumbnail: foto1929
    },{
      original: foto1930,
      thumbnail: foto1930
    },{
      original: foto1931,
      thumbnail: foto1931
    },{
      original: foto1932,
      thumbnail: foto1932
    },{
      original: foto1933,
      thumbnail: foto1934
    },{
      original: foto1935,
      thumbnail: foto1935
    },{
      original: foto1936,
      thumbnail: foto1936
    },{
      original: foto1937,
      thumbnail: foto1937
    },{
      original: foto1938,
      thumbnail: foto1938
    },{
      original: foto1939,
      thumbnail: foto1939
    },{
      original: foto1940,
      thumbnail: foto1940
    },{
      original: foto1941,
      thumbnail: foto1941
    },{
      original: foto1942,
      thumbnail: foto1942
    },{
      original: foto1943,
      thumbnail: foto1943
    },{
      original: foto1944,
      thumbnail: foto1944
    },{
      original: foto1945,
      thumbnail: foto1945
    },{
      original: foto1946,
      thumbnail: foto1946
    },{
      original: foto1947,
      thumbnail: foto1947
    }
  ];

  const images2018 = [

  ];

  const images2017 = [

  ];

  const images2016 = [

  ];

  const images2015 = [

  ];

  const images2014 = [

  ];

  const images2013 = [

  ];

  const images2012 = [

  ];

  const images2011 = [

  ];

  const images2010 = [

  ];

  const images2009 = [
    {
      original: foto0901,
      thumbnail: foto0901
    },{
      original: foto0902,
      thumbnail: foto0902
    },{
      original: foto0903,
      thumbnail: foto0903
    },{
      original: foto0904,
      thumbnail: foto0904
    },{
      original: foto0905,
      thumbnail: foto0905
    },{
      original: foto0906,
      thumbnail: foto0906
    },{
      original: foto0907,
      thumbnail: foto0907
    },{
      original: foto0908,
      thumbnail: foto0908
    },{
      original: foto0909,
      thumbnail: foto0909
    },
  ];

  const images2008 = [

  ];

  const images2007 = [

  ];

  const images2006 = [

  ];

  const images2005 = [
    {
      original: foto0501,
      thumbnail: foto0501
    },
    {
      original: foto0502,
      thumbnail: foto0502
    },
    {
      original: foto0503,
      thumbnail: foto0503
    },
    {
      original: foto0504,
      thumbnail: foto0504
    },
    {
      original: foto0505,
      thumbnail: foto0505
    },
    {
      original: foto0506,
      thumbnail: foto0506
    },
    {
      original: foto0507,
      thumbnail: foto0507
    },
    {
      original: foto0508,
      thumbnail: foto0508
    },
    {
      original: foto0509,
      thumbnail: foto0509
    },
    {
      original: foto0510,
      thumbnail: foto0510
    },
    {
      original: foto0511,
      thumbnail: foto0511
    },
    {
      original: foto0512,
      thumbnail: foto0512
    },
    {
      original: foto0513,
      thumbnail: foto0513
    },
    {
      original: foto0514,
      thumbnail: foto0514
    },
    {
      original: foto0515,
      thumbnail: foto0515
    },
    {
      original: foto0516,
      thumbnail: foto0516
    }
  ];

  const images2004 = [
    {
      original: auto,
      thumbnail: auto,
      originalAlt: "Fotka zelené dodávky"
    },
    {
      original: bazen,
      thumbnail: bazen,
      originalAlt: "Děti potápějící se v bazénu."
    },
    {
      original: policejniAutoBok,
      thumbnail: policejniAutoBok,
      originalAlt: "Fotka policejního auta"
    },
    {
      original: policejniAutoPred,
      thumbnail: policejniAutoPred,
      originalAlt: "Fotka policejního auta"
    },
    {
      original: hasicak,
      thumbnail: hasicak,
      originalAlt: "Fotka policejního auta"
    },
    {
      original: hasicakStrika,
      thumbnail: hasicakStrika,
      originalAlt: "Fotka policejního auta"
    },
    {
      original: detiHasici,
      thumbnail: detiHasici,
      originalAlt: "Fotka policejního auta"
    },
    {
      original: prazdneHriste,
      thumbnail: prazdneHriste,
      originalAlt: "Fotka policejního auta"
    },
    {
      original: obleky,
      thumbnail: obleky,
      originalAlt: "Fotka policejního auta"
    },
    {
      original: prehrada,
      thumbnail: prehrada,
      originalAlt: "Fotka policejního auta"
    },
    {
      original: reka,
      thumbnail: reka,
      originalAlt: "Fotka policejního auta"
    },
    {
      original: muzeum,
      thumbnail: muzeum,
      originalAlt: "Fotka policejního auta"
    },
    {
      original: zasah,
      thumbnail: zasah,
      originalAlt: "Fotka policejního auta"
    },
    {
      original: poliDodavka,
      thumbnail: poliDodavka,
      originalAlt: "Fotka policejní dodávky"
    },
  ];

function Photogalery() {
  return (
    <div className="Photogalery">
      <main id="foto">

        <h1>Foto</h1>
        <p>Zde budou fotografie</p> 

        
        <h2>2023 - Avatar</h2>
        <ImageGallery items={images2023}/>

        <h2>2022 - Jumanji</h2>
        <ImageGallery items={images2022}/>

        <h2>2021 - Specialisté</h2>
        <ImageGallery items={images2021}/>

        <h2>2020 - Dračí doupě</h2>
        <ImageGallery items={images2020}/>

        <h2>2019 - Fantastická zvířata a kde je najít</h2>
        <ImageGallery items={images2019}/>

        <h2>2018 - Harry Potter</h2>
        <ImageGallery items={images2018}/>

        <h2>2017 - Marvel, sraz hrdinů</h2>
        <ImageGallery items={images2017}/>

        <h2>2016 - MASH</h2>
        <ImageGallery items={images2016}/>

        <h2>2015 - Hvězdná brána</h2>
        <ImageGallery items={images2015}/>

        <h2>2014 - TempleTown</h2>
        <ImageGallery items={images2014}/>

        <h2>2013 - Harry Potter</h2>
        <ImageGallery items={images2013}/>

        <h2>2012 - Policejní akademie</h2>
        <ImageGallery items={images2012}/>

        <h2>2011 - Tajuplný ostrov</h2>
        <ImageGallery items={images2011}/>

        <h2>2010 - Cesta kolem světa za 80 dní</h2>
        <ImageGallery items={images2010}/>

        <h2>2009 - Fantazii se meze nekladou</h2>
        <ImageGallery items={images2009}/>

        <h2>2008 - Záchrané složky</h2>
        <ImageGallery items={images2008}/>

        <h2>2007 - Rytíři</h2>
        <ImageGallery items={images2007}/>

        <h2>2006 - Indiánské léto</h2>
        <ImageGallery items={images2006}/>

        <h2>2005 - Policejní akademie aneb maskáčové hrátky</h2>
        <ImageGallery items={images2005}/>

        <h2>2004 - Policejní akademie aneb maskáčové hrátky - Ročník 2</h2>
        <ImageGallery items={images2004}/>

        <h2>2003 - Policejní akademie aneb maskáčové hrátky</h2>
        <ImageGallery items={images2023}/>

        <h2>2002 - Nebezské sny - Život řeckých bohů</h2>
        <ImageGallery items={images2020}/>

      </main>
    </div>
  );
}

export default Photogalery;