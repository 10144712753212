

function TaborRad() {
    return (
        <div className="TaborRad">
            <main>
                <h1>Táborový Řád</h1>
                <ol>
                    <li>Po celou dobu tábora dodržujte vzorné chování.</li>
                    <li>Bez vědomí a svolení vedoucích nesmí nikdo odejít z tábora.</li>
                    <li>V chatkách (srubech), nejbližším okolí a přírodě dodržujte vzorný pořádek a čistotu.</li>
                    <li>Pitnou vodu používejte výhradně ze spolehlivých zdrojů pitné vody.</li>
                    <li>Oheň rozdělávejte jen na bezpečných místech  k tomu určených a výhradně jen s vedoucím oddílu.</li>
                    <li>Berte si jenom tolik jídla co sníte. V chatkách ani jinde se žádné jídlo nesmí schovávat.</li>
                    <li>V době nočního klidu se chovejte tiše. Nerušte ostatní.</li>
                    <li>Dodržujte zásady osobní hygieny a hygieny prostředí.</li>
                    <li>Pro úklid a ostatní činnost používejte jen nářadí, které je k tomu určené</li>
                    <li>Svým rodičům pište alespoň jednou týdně.</li>
                    <li>I sebemenší úrazy a zdravotní potíže hlaste svým vedoucím nebo táborovému zdravotníku.</li>
                    <li>Svévolné porušení tohoto táborového řádu může být potrestáno i vyloučením z tábora.</li>
                </ol>
            </main>
        </div>
    );
}


export default TaborRad;