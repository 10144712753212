import '../App.css';
import OSP from '../images/sponsors/OSP.png';
import optika from '../images/sponsors/optika.svg';
import { Link } from 'react-router-dom';

function Downbar() {
  return (
    <div className="Downbar">   
      <footer className='spodni'>
            <div className='sponsors'>
                <h3 className="h3inv">Sponzoři</h3>
                <a href="https://osp-mk.cz/"><img src={OSP} alt="Stavebniny OSP Moravský Krumlov" height="50px"/></a>
                <a href="https://www.optikakocandova.cz/"><img src={optika} alt="Optika Kocandová" height="50px" style={{backgroundColor: "rgb(236, 72, 50)"}}/></a>
            </div>
            <div className='navLinks'>
                <h3 className='h3inv'>Kam dál?</h3>
                <ul className='navList'>
                    <li><Link to='/'>Domů</Link></li>
                    <li><Link to='/Onas'>O nás</Link></li>
                    <li><Link to='/Kontakty'>Kontakty</Link></li>
                    <li><Link to='/Dokumenty'>Dokumenty</Link></li>
                </ul>
            </div>
        </footer>
    </div>
  );
}

export default Downbar;