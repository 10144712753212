import '../App.css';

import Bezinfekcnost from '../documents/Bezinfekcnost.docx';
import SouhlasRodicu from '../documents/PisemnySouhlas.docx';
import Zpusobilost from '../documents/Posudek_o_zdravotni_zpusobilosti.pdf';

function Documents() {
  return (
    <div className="Documents">
      <main>
        <h1>Dokumenty ke stažení</h1>
        <p>Důležité dokumenty, ke stažení</p>

          <h2>Informační dokumenty</h2>
          <p>Dokumenty pro informace a dokumenty.</p>

        <table align='Center'>
            <tr>
                <th>Název</th>
                <th>Soubor</th>
            </tr>
            <tr>
                <td>Pokyny na Letní Tábor</td>
                <td><a href="/dokumenty" download>Budou uveřejněny 1. 6.</a></td>
            </tr>
            <tr>
              <td>Táborový řád</td>
              <td><a href="/dokumenty" download>Bude uveřejněn 1. 6.</a></td>
          </tr>
          
        </table>

        <h2>Dokumenty k vyplnění</h2>
        <p>Dokumenty na vyplnění a odeslání</p>

        <table align='Center'>
            <tr>
                <th>Název</th>
                <th>Soubor</th>
            </tr>
          <tr>
            <td>Písemný souhlas rodičů - léky, foto - 2024</td>
            <td><a href={SouhlasRodicu} download>PisemnySouhlas.docx</a></td>
          </tr>
          <tr>
            <td>Posudek o zdravotní zpusobilosti</td>
            <td><a download href={Zpusobilost} target="_blank">posudek_o_zdravotní_způsobilosti.pdf</a></td>
          </tr>
          <tr>
            <td>Prohlášení o bezinfekčnosti</td>
            <td><a href={Bezinfekcnost} download>Bezinfekčnost - 2024</a></td>
          </tr>
        </table>
      </main>
    </div>
  );
}

export default Documents;