import '../App.css';

function Contact() {
  return (
    <div className="Contact">
              <main>
            <h1>Kontakty</h1>
        
            <h2>Adresa firmy</h2>

            <table className='kontaktBlok'>
                <tr>
                    <td colspan="2">Václavská 774 <br/> 672 01 Moravský Krumlov</td>
                </tr>   
            </table>

            <p>ČÚ: 4533680369/0800</p>

            <p>IČ: <a href="https://rejstrik-firem.kurzy.cz/zivnostnik/bXGXp5aWkaQ=" target="_blank" rel='noreferrer'>696 42 001</a></p>

            <h2>Hlavní vedoucí - Pořadatel</h2>
            <p className="kontaktJmeno">Mgr. Vladimíra Rozmahelová</p>
            <p>Telefonní číslo: <a href="tel:+420605252489">+420 605 252 489</a></p>
            <p>E-mail: <a href="mailto:vladimirarozmahelova@seznam.cz">vladimirarozmahelova@seznam.cz</a></p>

            <br/>


            <h2>IT podpora</h2>
            <p className="kontaktJmeno">Petr Walla</p>
            <p>E-mail: <a href="mailto:it@taborrozmahelova.cz">it@taborrozmahelova.cz</a></p>
        </main>
    </div>
  );
}

export default Contact;